
.eCommerce-container {
    height: 100%;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
        & > .el-scrollbar__view {
            /*min-width: 1400px;*/
        }
    }
}
.top-bar-con {
    position: relative;
    margin: 0 20px 0;
    padding: 8px 0 0;
}
.back-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
}
.goods-info {
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 20px;
    .good-img {
        width: 120px;
        height: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
            max-width: 100%;
        }
    }
    .info-right {
        width: 1%;
        flex: 1;
        margin-left: 16px;
        cursor: pointer;
        font-size: 18px;
    }
}
.shadow-box {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, .05);
    border-radius: 10px;
    background: #fff;
}
.data-content {
    margin: 20px;
    padding: 20px;
    .top-bar {
        width: 218px;
        height: 50px;
        background: url("../../../../assets/images/student/customer-info.png") no-repeat;
        .text {
            padding-left: 70px;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
        }
    }
    .line-data {
        display: flex;
        margin-top: 28px;
        border: 1px solid #E5E5E5;
        .line-item {
            width: 1%;
            flex: 1;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 16px;
            .item-left {
                width: 112px;
                height: 118px;
                background: #F8F8F8;
                img {
                    margin-top: 24px;
                }
                .text {
                    line-height: 1;
                    margin-top: 5px;
                }
            }
            .item-right {
                width: 1%;
                flex: 1;
                display: flex;
                .item {
                    width: 33.333333%;
                    .text {
                        margin-top: 20px;
                        font-size: 18px;
                        line-height: 1;
                    }
                }
            }
        }
    }
    .trend-wrapper {
        background: #fff;
        padding: 20px;
        .trend-category {
            display: flex;
            border: 1px solid #E5E5E5;
            .cate-left {
                width: 120px;
                background: #F8F8F8;
                p {
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    border-bottom: 1px solid #E5E5E5;
                    margin: 0;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
            .cate-right {
                width: 1%;
                flex: 1;
                .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    .el-checkbox {
                        margin-right: 0;
                        line-height: 50px;
                        margin-left: 48px;
                        width: calc(15% - 48px);
                        box-sizing: border-box;
                        &:nth-of-type(3) {
                            width: calc(70% - 48px);
                        }
                        &:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3) {
                            &:after {
                                content: '';
                                position: absolute;
                                left: -48px;
                                right: 0;
                                bottom: -1px;
                                height: 1px;
                                background: #E5E5E5;
                            }
                            /*border-bottom: 1px solid #E5E5E5;*/
                        }
                    }
                }
            }
        }
        .choose-number {
            margin-top: 30px;
            font-size: 18px;
            .number {
                margin-left: 20px;
                span {
                    color: #1AB163;
                }
            }
        }
        .trend-content {
            margin-top: 30px;
            height: 300px;
            width: 100%;
        }
    }
}
